<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="诉讼费" fixed>
      <fb-button v-if="!isShare" slot="left" icon="back" @click="backToNative()" size="small" type="primary"> 返回 </fb-button>
      <div slot="right">
        <tool-head type="litigation" @clickItem="clickItem">
          <font-size-comp @setFontSize="setFontSize" ref="fontSizeComp" />
        </tool-head>
      </div>
    </fb-header>
    <div id="tools" class="tools-litigation" style="font-size: 14px !important">
      <div class="box" style="padding: 15px 20px">
        <div @click="openType" class="box-left" style="display: flex; align-items: center; flex: 1">
          <div>{{ type }}</div>
          <van-icon name="arrow-down" />
        </div>
        <div class="box-right" v-show="type === '离婚案件'">
          <van-radio-group v-model="involvingProperty" direction="horizontal">
            <van-radio name="1">不涉及财产</van-radio>
            <van-radio name="2">涉及财产</van-radio>
          </van-radio-group>
        </div>
        <div class="box-right" v-show="type === '人格人权'">
          <van-radio-group v-model="involvingProperty" direction="horizontal">
            <van-radio name="1">不涉及赔偿</van-radio>
            <van-radio name="2">涉及赔偿</van-radio>
          </van-radio-group>
        </div>
        <div class="box-right" v-show="type === '知识产权'">
          <van-radio-group v-model="involvingProperty" direction="horizontal">
            <van-radio name="1">无争议金额</van-radio>
            <van-radio name="2">有争议金额</van-radio>
          </van-radio-group>
        </div>
      </div>
      <div
        class="box"
        style="padding: 0px 20px"
        v-show="type === '财产案件' || (type === '离婚案件' && involvingProperty === '2') || (type === '人格人权' && involvingProperty === '2') || (type === '知识产权' && involvingProperty === '2')"
      >
        <div class="box-left">
          金额<span v-show="money" style="margin-left: 5px">{{ yuanToWan(money) }}</span>
        </div>
        <div class="box-right" style="display: flex; align-items: center">
          <van-field
            v-model="money"
            input-align="right"
            placeholder="请输入金额"
            type="number"
            readonly
            clickable
            @touchstart.stop="show = true"
            style="flex: 1"
            :class="show ? 'van-number-keyboard-input-select' : ''"
          />
          <van-icon name="close" @click="money = ''" />
        </div>
      </div>
      <div class="box" style="padding: 15px 20px" @click="openOther">
        <div class="box-left" style="display: flex; align-items: center">
          <div>其他申请</div>
          <van-icon name="arrow-down" />
        </div>
        <div class="box-right" style="flex: 1">
          <div class="other-item" v-for="(item, i) in other" :key="i">
            {{ item }}
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <van-button plain type="info" @click="reset">重置</van-button>
        <van-button @click="getResult" style="margin-left:20px" type="info"
          >计算</van-button
        >
      </div> -->
      <div class="interest-result-list" v-show="result">
        <div v-for="(item, i) in result.resultList" :key="i">
          <span v-for="(text, index) in item" :key="index">{{ text }}</span
          ><i v-show="i === result.resultList.length - 1" @click="copy" style="color: #000000; margin-left: 20px; cursor: pointer" class="iconfont icon-fuzhi"></i>
        </div>
      </div>
      <div class="interest-result">
        <span @click="isShowInfo = !isShowInfo" v-show="result">详情</span>
        <span v-show="!result"></span>
        <span>
          <span @click="copyAll" style="margin-right: 10px" v-show="result">复制</span>
          <span @click="getWORD" style="margin-right: 10px" v-show="result && !iphone">导出word</span>
          <span @click="share" style="margin-right: 10px">分享</span>
          <span @click="feedback">反馈</span>
        </span>
      </div>
      <div class="interest-line"></div>
      <div v-show="isShowInfo" style="padding: 0 20px">
        <div v-for="(item, i) in result.processList" :key="i">
          <span v-for="(text, index) in item" :key="index" :style="index !== 0 ? 'margin-left:20px' : ''">{{ text }}</span>
        </div>
      </div>
      <div v-show="isShowInfo" style="margin-top: 20px; padding: 0 20px">
        <p v-for="(item, i) in result.accordanceList" :key="i">
          {{ item }}
        </p>
      </div>
    </div>
    <van-popup v-model="typeShow" position="top" :style="{ height: '30%' }">
      <cell-radio-group :radio.sync="type" :options="options" @select="typeChange" />
    </van-popup>
    <van-number-keyboard :show="show" theme="custom" extra-key="." close-button-text="完成" @blur="show = false" @input="onInput" @delete="onDelete" />
    <van-popup v-model="otherShow" position="top">
      <van-checkbox-group v-model="other" @change="otherChange">
        <van-cell-group>
          <van-cell v-for="(option, i) in options2" clickable :key="i" :title="option.label" @click="onClick(option, i)">
            <van-checkbox :name="option.value" ref="checkboxes" slot="right-icon" shape="square" />
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </van-popup>
  </fb-page>
</template>
<script>
import fontSizeComp from '../../components/font-size-comp.vue'
import goBackMixin from '@mixins/goBack'
import { qiniuBase } from '~api-config'
import cellRadioGroup from '../../components/cell-radio-group.vue'
import longUrl2ShortUrl from '@/common/longUrl2ShortUrlUtils.js'
import toolHead from '../../components/toolHead.vue'
export default {
  name: 'litigation',
  mixins: [goBackMixin],
  components: {
    fontSizeComp,
    cellRadioGroup,
    toolHead,
  },
  data() {
    return {
      isShare: false,
      fontSize: 14,
      typeShow: false,
      otherShow: false,
      show: false,
      options: [
        {
          value: '财产案件',
          label: '财产案件',
        },
        {
          value: '普通非财产案件',
          label: '普通非财产案件',
        },
        {
          value: '离婚案件',
          label: '离婚案件',
        },
        {
          value: '人格人权',
          label: '人格人权',
        },
        {
          value: '知识产权',
          label: '知识产权',
        },
        {
          value: '劳动争议',
          label: '劳动争议',
        },
        {
          value: '商标专利海事行政',
          label: '商标专利海事行政',
        },
        {
          value: '其他行政',
          label: '其他行政',
        },
        {
          value: '管辖权异议不成立',
          label: '管辖权异议不成立',
        },
      ],
      options2: [
        {
          value: '支付令',
          label: '支付令',
        },
        {
          value: '公示催告',
          label: '公示催告',
        },
        {
          value: '撤销仲裁裁决',
          label: '撤销仲裁裁决',
        },
        {
          value: '认定仲裁协议效力',
          label: '认定仲裁协议效力',
        },
        {
          value: '破产案件',
          label: '破产案件',
        },
        {
          value: '设立海事赔偿责任限制基金',
          label: '设立海事赔偿责任限制基金',
        },
        {
          value: '申请海事强制令',
          label: '申请海事强制令',
        },
        {
          value: '申请船舶优先权催告',
          label: '申请船舶优先权催告',
        },
        {
          value: '申请海事债权登记',
          label: '申请海事债权登记',
        },
        {
          value: '申请共同海损理算',
          label: '申请共同海损理算',
        },
      ],
      type: '财产案件', // 费用类别
      involvingProperty: '1', // 不涉及财产
      other: [], // 其他申请
      money: '', // 金额
      isShowInfo: false, // 是否显示详情
      result: '', // 计算结果
      feedbackVisible: false, // 反馈组件
      iphone: false,
    }
  },
  computed: {},
  methods: {
    clickItem(item) {
      this.addHistory()
      const { condition, type } = item
      const conditionObj = JSON.parse(condition)
      if (type === 'litigation') {
        const { type, involvingProperty, other, money } = conditionObj
        this.type = type
        this.involvingProperty = involvingProperty
        this.other = other
        this.money = money
      }
    },
    addHistory() {
      const type = 'litigation'
      const { involvingProperty, other, money } = this
      const conditionObj = {
        type: this.type,
        involvingProperty,
        other,
        money,
      }
      let title = ' ' + this.type + ' '
      title += money ? money + '元 ' : ''
      title += other ? other.join(',') + ' ' : ' '
      title += ' 诉讼费'
      if (this.result) {
        this.$axios
          .post(`${this.$base}/management/utils/history/add`, {
            type,
            condition: JSON.stringify(conditionObj),
            title,
          })
          .then((res) => {
            if (res.data.code === 200) {
              // this.$bus.$emit('add-history-suc', 'litigation')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 设置字体大小
    setFontSize() {},
    // 打开类型的弹框
    openType() {
      this.typeShow = true
    },
    // 类型选择改变
    typeChange() {
      this.typeShow = false
    },
    // 数字键盘组件输入事件
    onInput(value) {
      this.money += value + ''
    },
    // 数字键盘组件删除事件
    onDelete(value) {
      var money = this.money.toString()
      this.money = money.substring(0, money.length - 1)
    },
    // 将元转换成万元
    yuanToWan(money) {
      money = parseFloat(money)
      let result = ''
      const wan = parseInt(money / 10000)
      if (wan > 0) {
        result = wan + '万'
      }
      let yuan = money - wan * 10000
      yuan = parseFloat(yuan.toFixed(2))
      if (yuan !== 0) {
        result = result + yuan + '元'
      }
      return result
    },
    // 打开其他的选择
    openOther() {
      this.otherShow = true
    },
    // 其他选择改变
    otherChange(e) {
      this.other = e
    },
    // 点击其他的选项
    onClick(option, index) {
      this.$refs.checkboxes[index].toggle()
    },
    // 点击重置
    reset() {
      this.money = ''
      this.result = ''
      this.isShowInfo = false
      this.type = '财产案件' // 费用类别
      this.other = [] // 其他申请
      this.involvingProperty = '1'
    },
    // 点击计算按钮，获取结果
    getResult() {
      switch (this.type) {
        case '财产案件':
          this.getPropertyCase()
          break
        case '普通非财产案件':
          this.getOrdinaryNonPropertyCase()
          break
        case '离婚案件':
          this.getDivorceCase()
          break
        case '人格人权':
          this.getPersonalityTort()
          break
        case '知识产权':
          this.getIntellectualProperty()
          break
        case '劳动争议':
          this.getLaborDispute()
          break
        case '商标专利海事行政':
          this.getMaritimeAdministration()
          break
        case '其他行政':
          this.getOtherAdministration()
          break
        case '管辖权异议不成立':
          this.getNoJurisdiction()
          break
      }
    },
    // 执行费和保全费
    getZXFANDBQF(type, AccepFee) {
      let count = []
      this.result.accordanceList = [
        ...this.result.accordanceList,
        '第十四条 申请费分别按照下列标准交纳：',
        '(一)依法向人民法院申请执行人民法院发生法律效力的判决、裁定、调解书，仲裁机构依法作出的裁决和调解书，公证机关依法赋予强制执行效力的债权文书，申请承认和执行外国法院判决、裁定以及国外仲裁机构裁决的，按照下列标准交纳：',
        '1、没有执行金额或者价额的，每件交纳50元至500元。',
        '2、执行金额或者价额不超过1万元的，每件交纳50元；超过1万元至50万元的部分，按照1、5%交纳；超过50万元至500万元的部分，按照1%交纳；超过500万元至1000万元的部分，按照0、5%交纳；超过1000万元的部分,按照0、1%交纳。',
        '(二)申请保全措施的，根据实际保全的财产数额按照下列标准交纳：',
        '1、财产数额不超过1000元或者不涉及财产数额的，每件交纳30元；超过1000元至10万元的部分，按照1%交纳；超过10万元的部分，按照0、5%交纳。但是，当事人申请保全措施交纳的费用最多不超过5000元。',
      ]
      if (type === 1) {
        // 不涉及钱
        this.result.resultList.push(['保全费：30元'])
        this.result.resultList.push(['执行费：50~500元'])
        this.result.processList.push(['保全费=30元'])
        this.result.processList.push(['执行费=50~500元'])
        count = [AccepFee[0] + 30 + 50, AccepFee[1] + 30 + 500]
      } else {
        const resultList = []
        const processList = []
        const money = parseFloat(this.money)
        // 保全费
        let process2 = '' // 计算过程
        let result2 = 0 // 计算结果的值
        const list2 = [
          {
            min: 0,
            max: 1000,
            type: 0,
            value: 30,
          },
          {
            min: 1000,
            max: 100000,
            type: 1,
            value: 1,
          },
          {
            min: 100000,
            max: '',
            type: 1,
            value: 0.5,
          },
        ]
        for (var j = 0; j < list2.length; j++) {
          if (money > list2[j].min) {
            if (list2[j].max !== '') {
              // 如果不是最后一个
              if (money > list2[j].max) {
                // 当前是不是最后一个
                if (list2[j].type === 0) {
                  // 固定费用
                  result2 += list2[j].value
                  process2 += list2[j].value
                } else {
                  // 非固定费用
                  result2 += ((list2[j].max - list2[j].min) * list2[j].value) / 100
                  process2 += `+(${list2[j].max}-${list2[j].min})*${list2[j].value}%`
                }
              } else {
                // 当前是最后一个
                if (list2[j].type === 0) {
                  // 固定费用
                  result2 += list2[j].value
                  process2 += list2[j].value
                } else {
                  // 非固定费用
                  result2 += ((money - list2[j].min) * list2[j].value) / 100
                  process2 += `+(${money}-${list2[j].min})*${list2[j].value}%`
                }
              }
            } else {
              // 如果是最后一个
              result2 += ((money - list2[j].min) * list2[j].value) / 100
              process2 += `+(${money}-${list2[j].min})*${list2[j].value}%`
            }
          }
        }
        result2 = parseFloat(result2.toFixed(2))
        if (result2 > 5000) {
          result2 = 5000
          process2 += '>5000'
        }
        resultList.push([`保全费：${result2}元`])
        processList.push([`保全费=${process2}=${result2}元`])
        // 执行费
        let process3 = '' // 计算过程
        let result3 = 0 // 计算结果的值
        const list3 = [
          {
            min: 0,
            max: 10000,
            type: 0,
            value: 50,
          },
          {
            min: 10000,
            max: 500000,
            type: 1,
            value: 1.5,
          },
          {
            min: 500000,
            max: 5000000,
            type: 1,
            value: 1,
          },
          {
            min: 5000000,
            max: 10000000,
            type: 1,
            value: 0.5,
          },
          {
            min: 10000000,
            max: '',
            type: 1,
            value: 0.1,
          },
        ]
        if (money === 0 || money === '') {
          result3 = '50~500'
          resultList.push([`执行费：${result3}元`])
          processList.push([`执行费=$${result3}元`])
          count = [AccepFee[0] + result2 + 50, AccepFee[1] + result2 + 500]
        } else {
          for (var k = 0; k < list3.length; k++) {
            if (money > list3[k].min) {
              if (list3[k].max !== '') {
                // 如果不是最后一个
                if (money > list3[k].max) {
                  // 当前是不是最后一个
                  if (list3[k].type === 0) {
                    // 固定费用
                    result3 += list3[k].value
                    process3 += list3[k].value
                  } else {
                    // 非固定费用
                    result3 += ((list3[k].max - list3[k].min) * list3[k].value) / 100
                    process3 += `+(${list3[k].max}-${list3[k].min})*${list3[k].value}%`
                  }
                } else {
                  // 当前是最后一个
                  if (list3[k].type === 0) {
                    // 固定费用
                    result3 += list3[k].value
                    process3 += list3[k].value
                  } else {
                    // 非固定费用
                    result3 += ((money - list3[k].min) * list3[k].value) / 100
                    process3 += `+(${money}-${list3[k].min})*${list3[k].value}%`
                  }
                }
              } else {
                // 如果是最后一个
                result3 += ((money - list3[k].min) * list3[k].value) / 100
                process3 += `+(${money}-${list3[k].min})*${list3[k].value}%`
              }
            }
          }
          result3 = parseFloat(result3.toFixed(2))
          resultList.push([`执行费：${result3}元`])
          processList.push([`执行费=${process3}=${result3}元`])
          count = [AccepFee[0] + result2 + result3, AccepFee[1] + result2 + result3]
        }
        this.result.resultList = [...this.result.resultList, ...resultList]
        this.result.processList = [...this.result.processList, ...processList]
      }
      this.getOther(type, AccepFee, count)
    },

    // 财产案件
    getPropertyCase() {
      if (this.money === '') {
        // return this.$notify('金额不能为空')
        return
      }
      if (parseFloat(this.money) <= 0) {
        // return this.$notify('金额必须大于0')
        return
      }
      const money = parseFloat(this.money)
      const accordanceList = ['依据：根据《诉讼费用交纳办法》第十三条规定', '(一)财产案件根据诉讼请求的金额或者价额，按照下列比例分段累计交纳：'] // 法律依据
      const resultList = []
      const processList = []
      // 受理费
      let process = '' // 计算过程
      let result1 = 0 // 计算结果的值
      const list = [
        {
          text: '1、不超过1万元的，每件交纳50元',
          min: 0,
          max: 10000,
          type: 0,
          value: 50,
        },
        {
          text: '2、超过1万元至10万元的部分，按照2.5%交纳',
          min: 10000,
          max: 100000,
          type: 1,
          value: 2.5,
        },
        {
          text: '3、超过10万元至20万元的部分，按照2%交纳',
          min: 100000,
          max: 200000,
          type: 1,
          value: 2,
        },
        {
          text: '4、超过20万元至50万元的部分，按照1.5%交纳',
          min: 200000,
          max: 500000,
          type: 1,
          value: 1.5,
        },
        {
          text: '5、超过50万元至100万元的部分，按照1%交纳',
          min: 500000,
          max: 1000000,
          type: 1,
          value: 1,
        },
        {
          text: '6、超过100万元至200万元的部分，按照0.9%交纳',
          min: 1000000,
          max: 2000000,
          type: 1,
          value: 0.9,
        },
        {
          text: '7、超过200万元至500万元的部分，按照0.8%交纳',
          min: 2000000,
          max: 5000000,
          type: 1,
          value: 0.8,
        },
        {
          text: '8、超过500万元至1000万元的部分，按照0.7%交纳',
          min: 5000000,
          max: 10000000,
          type: 1,
          value: 0.7,
        },
        {
          text: '9、超过1000万元至2000万元的部分，按照0.6%交纳',
          min: 10000000,
          max: 20000000,
          type: 1,
          value: 0.6,
        },
        {
          text: '10、超过2000万元的部分，按照0.5%交纳',
          min: 20000000,
          max: '',
          type: 1,
          value: 0.5,
        },
      ]
      for (var i = 0; i < list.length; i++) {
        accordanceList.push(list[i].text)
        if (money > list[i].min) {
          if (list[i].max !== '') {
            // 如果不是最后一个
            if (money > list[i].max) {
              // 当前是不是最后一个
              if (list[i].type === 0) {
                // 固定费用
                result1 += list[i].value
                process += list[i].value
              } else {
                // 非固定费用
                result1 += ((list[i].max - list[i].min) * list[i].value) / 100
                process += `+(${list[i].max}-${list[i].min})*${list[i].value}%`
              }
            } else {
              // 当前是最后一个
              if (list[i].type === 0) {
                // 固定费用
                result1 += list[i].value
                process += list[i].value
              } else {
                // 非固定费用
                result1 += ((money - list[i].min) * list[i].value) / 100
                process += `+(${money}-${list[i].min})*${list[i].value}%`
              }
            }
          } else {
            // 如果是最后一个
            result1 += ((money - list[i].min) * list[i].value) / 100
            process += `+(${money}-${list[i].min})*${list[i].value}%`
          }
        }
      }
      result1 = parseFloat(result1.toFixed(2))
      resultList.push([`受理费：${result1}元`, `减半${parseFloat((result1 / 2).toFixed(2))}元`])
      processList.push([`受理费=${process}=${result1}元`, `减半${parseFloat((result1 / 2).toFixed(2))}元`])
      const count = [result1, result1]
      this.result = {
        resultList: resultList,
        processList: processList,
        accordanceList: accordanceList,
      }
      this.getZXFANDBQF(2, count)
    },

    // 普通非财产案件
    getOrdinaryNonPropertyCase() {
      const accordanceList = ['依据：根据《诉讼费用交纳办法》第十三条规定', '(二)非财产案件按照下列标准交纳：', '3、其他非财产案件每件交纳50元至100元。'] // 法律依据
      const resultList = []
      const processList = []
      resultList.push(['受理费：50~100元', '减半25~50元'])
      processList.push(['受理费=50~100元', '减半25~50元'])
      const count = [50, 100]
      this.result = {
        resultList: resultList,
        processList: processList,
        accordanceList: accordanceList,
      }
      this.getZXFANDBQF(1, count)
    },

    // 离婚案件
    getDivorceCase() {
      const accordanceList = [
        '依据：根据《诉讼费用交纳办法》第十三条规定',
        '(二)非财产案件按照下列标准交纳：',
        '1、离婚案件每件交纳50元至300元。涉及财产分割，财产总额不超过20万元的，不另行交纳；超过20万元的部分，按照0.5%交纳。',
      ] // 法律依据
      const resultList = []
      const processList = []
      let count = []
      let type = 1
      if (this.involvingProperty === '1') {
        resultList.push(['受理费：50~300元', '减半25~150元'])
        processList.push(['受理费=50~300元', '减半25~150元'])
        count = [50, 300]
        type = 1
      } else {
        if (this.money === '') {
          // return this.$notify('金额不能为空')
          return
        }
        // 受理费
        type = 2
        const money = parseFloat(this.money)
        if (money <= 200000) {
          resultList.push(['受理费：50~300元', '减半25~150元'])
          processList.push(['受理费=50~300元', '减半25~150元'])
          count = [50, 300]
        } else {
          const result = parseFloat((((money - 200000) * 0.5) / 100).toFixed(2))
          const min = result + 50
          const max = result + 300
          resultList.push([`受理费：${min}~${max}元`, `减半${min / 2}~${max / 2}元`])
          processList.push([`受理费=50~300+(${money - 200000}*0.5%)=${min}~${max}元`, `减半${min / 2}~${max / 2}元`])
          count = [min, max]
        }
      }
      this.result = {
        resultList: resultList,
        processList: processList,
        accordanceList: accordanceList,
      }
      this.getZXFANDBQF(type, count)
    },

    // 人格人权
    getPersonalityTort() {
      const accordanceList = [
        '依据：根据《诉讼费用交纳办法》第十三条规定',
        '(二)非财产案件按照下列标准交纳：',
        '2、侵害姓名权、名称权、肖像权、名誉权、荣誉权以及其他人格权的案件，每件交纳100元至500元。涉及损害赔偿，赔偿金额不超过5万元的，不另行交纳；超过5万元至10万元的部分，按照1%交纳；超过10万元的部分，按照0、5%交纳。',
      ] // 法律依据
      const resultList = []
      const processList = []
      let count = []
      let type = 1
      if (this.involvingProperty === '1') {
        resultList.push(['受理费：100~500元', '减半50~250元'])
        processList.push(['受理费=100~500元', '减半50~250元'])
        count = [100, 500]
        type = 1
      } else {
        if (this.money === '') {
          // return this.$notify('金额不能为空')
          return
        }
        // 受理费
        type = 2
        const money = parseFloat(this.money)
        if (money <= 50000) {
          resultList.push(['受理费：100~500元', '减半50~250元'])
          processList.push(['受理费=100~500元', '减半50~250元'])
          count = [100, 500]
        } else {
          let result1 = 0
          let process = ''
          const list = [
            {
              min: 50000,
              max: 100000,
              type: 1,
              value: 1,
            },
            {
              min: 100000,
              max: '',
              type: 1,
              value: 0.5,
            },
          ]
          for (var i = 0; i < list.length; i++) {
            if (money > list[i].min) {
              if (list[i].max !== '') {
                // 如果不是最后一个
                if (money > list[i].max) {
                  // 当前是不是最后一个
                  if (list[i].type === 0) {
                    // 固定费用
                    result1 += list[i].value
                    process += list[i].value
                  } else {
                    // 非固定费用
                    result1 += ((list[i].max - list[i].min) * list[i].value) / 100
                    process += `+(${list[i].max}-${list[i].min})*${list[i].value}%`
                  }
                } else {
                  // 当前是最后一个
                  if (list[i].type === 0) {
                    // 固定费用
                    result1 += list[i].value
                    process += list[i].value
                  } else {
                    // 非固定费用
                    result1 += ((money - list[i].min) * list[i].value) / 100
                    process += `+(${money}-${list[i].min})*${list[i].value}%`
                  }
                }
              } else {
                // 如果是最后一个
                result1 += ((money - list[i].min) * list[i].value) / 100
                process += `+(${money}-${list[i].min})*${list[i].value}%`
              }
            }
          }
          // result1 = parseFloat(result1.toFixed(2))
          const min = result1 + 100
          const max = result1 + 500
          process = '100~500' + process
          resultList.push([`受理费：${min}~${max}元`, `减半${min / 2}~${max / 2}元`])
          processList.push([`受理费=${process}=${result1}元`, `减半${min / 2}~${max / 2}元`])
          // processList.push([
          //   `受理费==${min}~${max}元`,
          //   `减半${min / 2}~${max / 2}元`
          // ])
          count = [min, max]
        }
      }
      this.result = {
        resultList: resultList,
        processList: processList,
        accordanceList: accordanceList,
      }
      this.getZXFANDBQF(type, count)
    },

    // 知识产权
    getIntellectualProperty() {
      const accordanceList = ['依据：根据《诉讼费用交纳办法》第十三条规定', '(三)知识产权民事案件，没有争议金额或者价额的，每件交纳500元至1000元；有争议金额或者价额的，按照财产案件的标准交纳。'] // 法律依据
      const resultList = []
      const processList = []
      let count = []
      let type = 1
      if (this.involvingProperty === '1') {
        resultList.push(['受理费：500~1000元', '减半250~500元'])
        processList.push(['受理费=500~1000元', '减半250~500元'])
        count = [500, 1000]
        type = 1
      } else {
        type = 2
        if (this.money === '') {
          // return this.$notify('金额不能为空')
          return
        }
        if (parseFloat(this.money) <= 0) {
          // return this.$notify('金额必须大于0')
          return
        }
        const money = parseFloat(this.money)
        // 受理费
        let process = '' // 计算过程
        let result1 = 0 // 计算结果的值
        const list = [
          {
            text: '1、不超过1万元的，每件交纳50元',
            min: 0,
            max: 10000,
            type: 0,
            value: 50,
          },
          {
            text: '2、超过1万元至10万元的部分，按照2.5%交纳',
            min: 10000,
            max: 100000,
            type: 1,
            value: 2.5,
          },
          {
            text: '3、超过10万元至20万元的部分，按照2%交纳',
            min: 100000,
            max: 200000,
            type: 1,
            value: 2,
          },
          {
            text: '4、超过20万元至50万元的部分，按照1.5%交纳',
            min: 200000,
            max: 500000,
            type: 1,
            value: 1.5,
          },
          {
            text: '5、超过50万元至100万元的部分，按照1%交纳',
            min: 500000,
            max: 1000000,
            type: 1,
            value: 1,
          },
          {
            text: '6、超过100万元至200万元的部分，按照0.9%交纳',
            min: 1000000,
            max: 2000000,
            type: 1,
            value: 0.9,
          },
          {
            text: '7、超过200万元至500万元的部分，按照0.8%交纳',
            min: 2000000,
            max: 5000000,
            type: 1,
            value: 0.8,
          },
          {
            text: '8、超过500万元至1000万元的部分，按照0.7%交纳',
            min: 5000000,
            max: 10000000,
            type: 1,
            value: 0.7,
          },
          {
            text: '9、超过1000万元至2000万元的部分，按照0.6%交纳',
            min: 10000000,
            max: 20000000,
            type: 1,
            value: 0.6,
          },
          {
            text: '10、超过2000万元的部分，按照0.5%交纳',
            min: 20000000,
            max: '',
            type: 1,
            value: 0.5,
          },
        ]
        for (var i = 0; i < list.length; i++) {
          if (money > list[i].min) {
            if (list[i].max !== '') {
              // 如果不是最后一个
              if (money > list[i].max) {
                // 当前是不是最后一个
                if (list[i].type === 0) {
                  // 固定费用
                  result1 += list[i].value
                  process += list[i].value
                } else {
                  // 非固定费用
                  result1 += ((list[i].max - list[i].min) * list[i].value) / 100
                  process += `+(${list[i].max}-${list[i].min})*${list[i].value}%`
                }
              } else {
                // 当前是最后一个
                if (list[i].type === 0) {
                  // 固定费用
                  result1 += list[i].value
                  process += list[i].value
                } else {
                  // 非固定费用
                  result1 += ((money - list[i].min) * list[i].value) / 100
                  process += `+(${money}-${list[i].min})*${list[i].value}%`
                }
              }
            } else {
              // 如果是最后一个
              result1 += ((money - list[i].min) * list[i].value) / 100
              process += `+(${money}-${list[i].min})*${list[i].value}%`
            }
          }
        }
        result1 = parseFloat(result1.toFixed(2))
        resultList.push([`受理费：${result1}元`, `减半${parseFloat((result1 / 2).toFixed(2))}元`])
        processList.push([`受理费=${process}=${result1}元`, `减半${parseFloat((result1 / 2).toFixed(2))}元`])
        count = [result1, result1]
      }
      this.result = {
        resultList: resultList,
        processList: processList,
        accordanceList: accordanceList,
      }
      this.getZXFANDBQF(type, count)
    },

    // 劳动争议
    getLaborDispute() {
      const accordanceList = ['依据：根据《诉讼费用交纳办法》第十三条规定', '（四）劳动争议案件每件交纳10元。'] // 法律依据
      const resultList = []
      const processList = []
      resultList.push(['受理费：10元', '减半5元'])
      processList.push(['受理费=10元', '减半5元'])
      const count = [10, 10]
      this.result = {
        resultList: resultList,
        processList: processList,
        accordanceList: accordanceList,
      }
      this.getZXFANDBQF(1, count)
    },

    // 海事行政
    getMaritimeAdministration() {
      const accordanceList = ['依据：根据《诉讼费用交纳办法》第十三条规定', '(五)行政案件按照下列标准交纳：', '1、商标、专利、海事行政案件每件交纳100元；'] // 法律依据
      const resultList = []
      const processList = []
      resultList.push(['受理费：100元', '减半50元'])
      processList.push(['受理费=100元', '减半50元'])
      const count = [100, 100]
      this.result = {
        resultList: resultList,
        processList: processList,
        accordanceList: accordanceList,
      }
      this.getZXFANDBQF(1, count)
    },

    // 其他行政
    getOtherAdministration() {
      const accordanceList = ['依据：根据《诉讼费用交纳办法》第十三条规定', '(五)行政案件按照下列标准交纳：', '2、其他行政案件每件交纳50元。'] // 法律依据
      const resultList = []
      const processList = []
      resultList.push(['受理费：50元', '减半25元'])
      processList.push(['受理费=50元', '减半25元'])
      const count = [50, 50]
      this.result = {
        resultList: resultList,
        processList: processList,
        accordanceList: accordanceList,
      }
      this.getZXFANDBQF(1, count)
    },

    // 管辖权异议不成立
    getNoJurisdiction() {
      const accordanceList = ['依据：根据《诉讼费用交纳办法》第十三条规定', '(六)当事人提出案件管辖权异议，异议不成立的，每件交纳50元至100元。'] // 法律依据
      const resultList = []
      const processList = []
      resultList.push(['受理费：50~100元', '减半25~50元'])
      processList.push(['受理费=50~100元', '减半25~50元'])
      const count = [50, 100]
      this.result = {
        resultList: resultList,
        processList: processList,
        accordanceList: accordanceList,
      }
      this.getZXFANDBQF(1, count)
    },

    // 计算其他
    getOther(type, AccepFee, count) {
      let flag = false
      if (this.other.length > 0) {
        this.other.map((item) => {
          switch (item) {
            case '支付令':
              var result = this.getPaymentOrder(AccepFee)
              count = [count[0] + result[0], count[1] + result[1]]
              break
            case '公示催告':
              var result1 = this.getPublicNotice()
              count = [count[0] + result1[0], count[1] + result1[1]]
              break
            case '撤销仲裁裁决':
              var result2 = this.getArbitrationAward('撤销仲裁裁决')
              count = [count[0] + result2[0], count[1] + result2[1]]
              break
            case '认定仲裁协议效力':
              var result9 = this.getArbitrationAward('认定仲裁协议效力')
              count = [count[0] + result9[0], count[1] + result9[1]]
              break
            case '破产案件':
              var result3 = this.getBankruptcyCase(AccepFee)
              count = [count[0] + result3[0], count[1] + result3[1]]
              break
            case '设立海事赔偿责任限制基金':
              var result4 = this.getMaritime1(flag, '1、申请设立海事赔偿责任限制基金的，每件交纳1000元至1万元；')
              count = [count[0] + result4[0], count[1] + result4[1]]
              flag = true
              break
            case '申请海事强制令':
              var result5 = this.getMaritime2(flag, '2、申请海事强制令的，每件交纳1000元至5000元；', '申请海事强制令')
              count = [count[0] + result5[0], count[1] + result5[1]]
              flag = true
              break
            case '申请船舶优先权催告':
              var result6 = this.getMaritime2(flag, '3、申请船舶优先权催告的，每件交纳1000元至5000元；', '申请船舶优先权催告')
              count = [count[0] + result6[0], count[1] + result6[1]]
              flag = true
              break
            case '申请海事债权登记':
              var result7 = this.getMaritime3(flag, '4、申请海事债权登记的，每件交纳1000元；', '申请海事债权登记')
              count = [count[0] + result7[0], count[1] + result7[1]]
              flag = true
              break
            case '申请共同海损理算':
              var result8 = this.getMaritime3(flag, '5、申请共同海损理算的，每件交纳1000元。', '申请共同海损理算')
              count = [count[0] + result8[0], count[1] + result8[1]]
              flag = true
              break
          }
        })
      }
      const resultList = []
      let countMoney = ''
      if (count[0] === count[1]) {
        resultList.push([`合计：${parseFloat(count[0].toFixed(2))}元`])
        countMoney = `${parseFloat(count[0].toFixed(2))}元`
      } else {
        resultList.push([`合计：${parseFloat(count[0].toFixed(2))}~${parseFloat(count[1].toFixed(2))}元`])
        countMoney = `${parseFloat(count[0].toFixed(2))}~${parseFloat(count[1].toFixed(2))}元`
      }
      this.result.resultList = [...this.result.resultList, ...resultList]
      this.result.money = this.money
      this.result.countMoney = countMoney
      this.result.type = this.type
      this.result.other = this.other
    },

    // 支付令
    getPaymentOrder(AccepFee) {
      this.result.accordanceList = [...this.result.accordanceList, '(三)依法申请支付令的，比照财产案件受理费标准的1/3交纳。']
      let min = 0
      let max = 0
      const resultList = []
      const processList = []
      if (AccepFee[0] === AccepFee[1]) {
        min = max = parseFloat((AccepFee[0] / 3).toFixed(2))
        resultList.push([`支付令：${min}元`])
        processList.push([`支付令=${AccepFee[0]}/3=${min}元`])
      } else {
        min = parseFloat((AccepFee[0] / 3).toFixed(2))
        max = parseFloat((AccepFee[1] / 3).toFixed(2))
        resultList.push([`支付令：${min}~${max}元`])
        processList.push([`支付令=${AccepFee[0]}~${AccepFee[1]}/3=${min}~${max}元`])
      }
      this.result.resultList = [...this.result.resultList, ...resultList]
      this.result.processList = [...this.result.processList, ...processList]
      return [min, max]
    },

    // 公示催告
    getPublicNotice() {
      this.result.accordanceList = [...this.result.accordanceList, '(四)依法申请公示催告的，每件交纳100元。']
      const min = 100
      const max = 100
      const resultList = []
      const processList = []
      resultList.push(['公示催告：100元'])
      processList.push(['公示催告=100元'])
      this.result.resultList = [...this.result.resultList, ...resultList]
      this.result.processList = [...this.result.processList, ...processList]
      return [min, max]
    },

    // 撤销仲裁裁决
    getArbitrationAward(title) {
      this.result.accordanceList = [...this.result.accordanceList, '(五)申请撤销仲裁裁决或者认定仲裁协议效力的，每件交纳400元。']
      const min = 400
      const max = 400
      const resultList = []
      const processList = []
      resultList.push([`${title}：400元`])
      processList.push([`${title}=400元`])
      this.result.resultList = [...this.result.resultList, ...resultList]
      this.result.processList = [...this.result.processList, ...processList]
      return [min, max]
    },

    // 破产案件
    getBankruptcyCase(AccepFee) {
      this.result.accordanceList = [...this.result.accordanceList, '(六)破产案件依据破产财产总额计算，按照财产案件受理费标准减半交纳，但是，最高不超过30万元。']
      let min = 0
      let max = 0
      const resultList = []
      const processList = []
      if (AccepFee[0] === AccepFee[1]) {
        min = parseFloat((AccepFee[0] / 2).toFixed(2))
        min = min > 300000 ? 300000 : min
        max = min
        resultList.push([`破产案件：${min}元`])
        processList.push([`破产案件=${AccepFee[0]}/2=${min}元`])
      } else {
        min = parseFloat((AccepFee[0] / 2).toFixed(2))
        min = min > 300000 ? 300000 : min
        max = parseFloat((AccepFee[1] / 2).toFixed(2))
        max = max > 300000 ? 300000 : max
        if (min === max) {
          resultList.push([`破产案件：${min}元`])
          processList.push([`破产案件=${AccepFee[0]}/2=${min}元`])
        } else {
          resultList.push([`破产案件：${min}~${max}元`])
          processList.push([`破产案件=${AccepFee[0]}~${AccepFee[1]}/2=${min}~${max}元`])
        }
      }
      this.result.resultList = [...this.result.resultList, ...resultList]
      this.result.processList = [...this.result.processList, ...processList]
      return [min, max]
    },

    // 设立海事赔偿责任限制基金
    getMaritime1(flag, text) {
      const accordanceList = []
      if (flag === false) {
        accordanceList.push('(七)海事案件的申请费按照下列标准交纳：')
      }
      accordanceList.push(text)
      this.result.accordanceList = [...this.result.accordanceList, ...accordanceList]
      const min = 1000
      const max = 10000
      const resultList = []
      const processList = []
      resultList.push(['设立海事赔偿责任限制基金：1000~10000元'])
      processList.push(['设立海事赔偿责任限制基金=1000~10000元'])
      this.result.resultList = [...this.result.resultList, ...resultList]
      this.result.processList = [...this.result.processList, ...processList]
      return [min, max]
    },

    // 申请海事强制令 & 申请船舶优先权催告
    getMaritime2(flag, text, title) {
      const accordanceList = []
      if (flag === false) {
        accordanceList.push('(七)海事案件的申请费按照下列标准交纳：')
      }
      accordanceList.push(text)
      this.result.accordanceList = [...this.result.accordanceList, ...accordanceList]
      const min = 1000
      const max = 5000
      const resultList = []
      const processList = []
      resultList.push([`${title}：1000~5000元`])
      processList.push([`${title}=1000~5000元`])
      this.result.resultList = [...this.result.resultList, ...resultList]
      this.result.processList = [...this.result.processList, ...processList]
      return [min, max]
    },

    // 申请海事债权登记 & 申请共同海损理算
    getMaritime3(flag, text, title) {
      const accordanceList = []
      if (flag === false) {
        accordanceList.push('(七)海事案件的申请费按照下列标准交纳：')
      }
      accordanceList.push(text)
      this.result.accordanceList = [...this.result.accordanceList, ...accordanceList]
      const min = 1000
      const max = 1000
      const resultList = []
      const processList = []
      resultList.push([`${title}：1000元`])
      processList.push([`${title}=1000元`])
      this.result.resultList = [...this.result.resultList, ...resultList]
      this.result.processList = [...this.result.processList, ...processList]
      return [min, max]
    },
    // 复制全部内容
    async copyAll() {
      let str = ''
      this.result.processList.map((item) => {
        str += `${item}\r\n`
      })
      this.result.accordanceList.map((item) => {
        str += `${item}\r\n`
      })
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = str // 这里表示想要复制的内容
      let url = window.location.href
      const res = await longUrl2ShortUrl(url)
      if (res.status === 200) {
        url = res.data
        str += '\n' + url
      }
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
      this.addHistory()
    },
    // 点击导出word
    getWORD() {
      let str = ''
      this.result.resultList.map((list) => {
        list.map((item) => {
          str += `${item}\t`
        })
        str += '\n'
      })
      this.result.processList.map((item) => {
        str += `${item}\n`
      })
      this.getTxt(str)
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    // 生成txt
    getTxt(text) {
      // const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', time)
      var blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
      console.log(blob)
      const file = new File([blob], '计算结果.txt', {
        type: 'text/plain;charset=utf-8',
      })
      console.log(file)
      const qiniu = new this.$qiniu()
      qiniu.UploadFile(
        'DEFAULT',
        file,
        {
          next: (file) => {},
          complete: () => {},
          error: () => {
            this.$toast('上传失败，上传终止')
          },
        },
        (fileopt, key, file) => {
          // 拼接完整的图片的地址
          const url = window.location.protocol + qiniuBase + '/' + key
          const name = url.substring(url.lastIndexOf('/') + 1)
          // const pathUrl = url.substring(0, url.lastIndexOf('/'))
          const ua = navigator.userAgent
          if (ua === this.$ua.a) {
            // eslint-disable-next-line
            // AND2JS.showFilePreview(url)
            window.showFilePreview.postMessage(
              JSON.stringify({
                filePath: url,
                fileName: name,
              })
            )
          } else if (ua === 'fb-flutter-Webview') {
            // flutter环境
            // eslint-disable-next-line
            try {
              // 之前写的方法
              // window.fbDownloadFile.postMessage(url)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: url,
                  fileName: name,
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.i) {
            this.iphone = true
            // ios环境
            // eslint-disable-next-line
            // window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
          } else {
            // window.location = url
            console.log('下载地址:', url)
          }
        }
      )
    },

    // 点击复制图标
    copy() {
      let text = ''
      this.result.resultList.map((item) => {
        text += `${item}\r\n`
      })
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
      this.addHistory()
    },

    // 点击分享
    async share() {
      let text = window.location.href
      if (this.result) {
        const obj = {
          money: this.result.money,
          type: this.result.type,
          other: this.result.other,
        }
        text += `?data=${JSON.stringify(obj)}`
      }
      const res = await longUrl2ShortUrl(text)
      if (res.status === 200) {
        text = res.data
      }
      let title = ''
      if (this.result.countMoney) {
        title = `诉讼费${this.result.countMoney}`
      } else {
        title = '诉讼费'
      }
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes(title, '律呗诉讼费计算工具', window.encodeURI(text))
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: title,
            url: window.encodeURI(text),
          })
        )
      } else {
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已复制本网页地址，请粘贴到QQ或微信中',
        })
      }
      this.addHistory()
    },

    // 点击反馈
    feedback() {
      this.$router.push({
        name: 'feedback',
      })
    },
  },
  created() {
    if (this.$route.query.data) {
      const data = JSON.parse(this.$route.query.data)
      this.money = data.money
      this.type = data.type
      this.other = data.other
      this.isShare = true
      this.getResult()
    } else {
      this.isShare = false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.fontSizeComp.fontSize = 20
      this.$refs.fontSizeComp.setFontSize()
    })
  },
  watch: {
    money(value) {
      this.money = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.getResult()
    },
    type(value) {
      this.getResult()
    },
    other(value) {
      this.getResult()
    },
    involvingProperty(value) {
      this.getResult()
    },
  },
}
</script>
<style lang="stylus">
.van-number-keyboard-input-select
  background rgba(25,137,250,0.3)
.tools-litigation
  position relative
  & .box
    box-sizing border-box
    width 100%
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px solid #CCC
    & .van-radio-group
      display flex
      align-items center
    & .box-right
      display flex
      flex-direction row
      align-items center
      justify-content flex-end
      flex-wrap wrap
      & .other-item
        padding 5px 10px
        margin-top 5px
        margin-left 10px
        box-sizing border-box
        background rgba(0,0,0,0.5)
        color #FFF
  & .btn
    margin-top 20px
    display flex
    align-items center
    justify-content center
  & .interest-result-list
    padding 0 20px
    box-sizing border-box
    color red
    & > div
      padding 5px 0
      box-sizing border-box
      display flex
      flex-direction row
      align-items center
      justify-content space-between
  & .interest-result
    box-sizing border-box
    padding 0 20px
    margin-top 20px
    display flex
    flex-direction row
    align-items center
    color red
    justify-content space-between
    & > span
      cursor pointer
      color #0079FE
  & .interest-line
    width 100%
    height 1px
    background #cccccc
    margin 10px 0
</style>
