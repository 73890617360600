var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "诉讼费", fixed: "" },
          slot: "header",
        },
        [
          !_vm.isShare
            ? _c(
                "fb-button",
                {
                  attrs: {
                    slot: "left",
                    icon: "back",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.backToNative()
                    },
                  },
                  slot: "left",
                },
                [_vm._v(" 返回 ")]
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "tool-head",
                {
                  attrs: { type: "litigation" },
                  on: { clickItem: _vm.clickItem },
                },
                [
                  _c("font-size-comp", {
                    ref: "fontSizeComp",
                    on: { setFontSize: _vm.setFontSize },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tools-litigation",
          staticStyle: { "font-size": "14px !important" },
          attrs: { id: "tools" },
        },
        [
          _c(
            "div",
            { staticClass: "box", staticStyle: { padding: "15px 20px" } },
            [
              _c(
                "div",
                {
                  staticClass: "box-left",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    flex: "1",
                  },
                  on: { click: _vm.openType },
                },
                [
                  _c("div", [_vm._v(_vm._s(_vm.type))]),
                  _c("van-icon", { attrs: { name: "arrow-down" } }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.type === "离婚案件",
                      expression: "type === '离婚案件'",
                    },
                  ],
                  staticClass: "box-right",
                },
                [
                  _c(
                    "van-radio-group",
                    {
                      attrs: { direction: "horizontal" },
                      model: {
                        value: _vm.involvingProperty,
                        callback: function ($$v) {
                          _vm.involvingProperty = $$v
                        },
                        expression: "involvingProperty",
                      },
                    },
                    [
                      _c("van-radio", { attrs: { name: "1" } }, [
                        _vm._v("不涉及财产"),
                      ]),
                      _c("van-radio", { attrs: { name: "2" } }, [
                        _vm._v("涉及财产"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.type === "人格人权",
                      expression: "type === '人格人权'",
                    },
                  ],
                  staticClass: "box-right",
                },
                [
                  _c(
                    "van-radio-group",
                    {
                      attrs: { direction: "horizontal" },
                      model: {
                        value: _vm.involvingProperty,
                        callback: function ($$v) {
                          _vm.involvingProperty = $$v
                        },
                        expression: "involvingProperty",
                      },
                    },
                    [
                      _c("van-radio", { attrs: { name: "1" } }, [
                        _vm._v("不涉及赔偿"),
                      ]),
                      _c("van-radio", { attrs: { name: "2" } }, [
                        _vm._v("涉及赔偿"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.type === "知识产权",
                      expression: "type === '知识产权'",
                    },
                  ],
                  staticClass: "box-right",
                },
                [
                  _c(
                    "van-radio-group",
                    {
                      attrs: { direction: "horizontal" },
                      model: {
                        value: _vm.involvingProperty,
                        callback: function ($$v) {
                          _vm.involvingProperty = $$v
                        },
                        expression: "involvingProperty",
                      },
                    },
                    [
                      _c("van-radio", { attrs: { name: "1" } }, [
                        _vm._v("无争议金额"),
                      ]),
                      _c("van-radio", { attrs: { name: "2" } }, [
                        _vm._v("有争议金额"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.type === "财产案件" ||
                    (_vm.type === "离婚案件" &&
                      _vm.involvingProperty === "2") ||
                    (_vm.type === "人格人权" &&
                      _vm.involvingProperty === "2") ||
                    (_vm.type === "知识产权" && _vm.involvingProperty === "2"),
                  expression:
                    "type === '财产案件' || (type === '离婚案件' && involvingProperty === '2') || (type === '人格人权' && involvingProperty === '2') || (type === '知识产权' && involvingProperty === '2')",
                },
              ],
              staticClass: "box",
              staticStyle: { padding: "0px 20px" },
            },
            [
              _c("div", { staticClass: "box-left" }, [
                _vm._v(" 金额"),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.money,
                        expression: "money",
                      },
                    ],
                    staticStyle: { "margin-left": "5px" },
                  },
                  [_vm._v(_vm._s(_vm.yuanToWan(_vm.money)))]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "box-right",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _c("van-field", {
                    class: _vm.show ? "van-number-keyboard-input-select" : "",
                    staticStyle: { flex: "1" },
                    attrs: {
                      "input-align": "right",
                      placeholder: "请输入金额",
                      type: "number",
                      readonly: "",
                      clickable: "",
                    },
                    on: {
                      touchstart: function ($event) {
                        $event.stopPropagation()
                        _vm.show = true
                      },
                    },
                    model: {
                      value: _vm.money,
                      callback: function ($$v) {
                        _vm.money = $$v
                      },
                      expression: "money",
                    },
                  }),
                  _c("van-icon", {
                    attrs: { name: "close" },
                    on: {
                      click: function ($event) {
                        _vm.money = ""
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "box",
              staticStyle: { padding: "15px 20px" },
              on: { click: _vm.openOther },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box-left",
                  staticStyle: { display: "flex", "align-items": "center" },
                },
                [
                  _c("div", [_vm._v("其他申请")]),
                  _c("van-icon", { attrs: { name: "arrow-down" } }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box-right", staticStyle: { flex: "1" } },
                _vm._l(_vm.other, function (item, i) {
                  return _c("div", { key: i, staticClass: "other-item" }, [
                    _vm._v(" " + _vm._s(item) + " "),
                  ])
                }),
                0
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.result,
                  expression: "result",
                },
              ],
              staticClass: "interest-result-list",
            },
            _vm._l(_vm.result.resultList, function (item, i) {
              return _c(
                "div",
                { key: i },
                [
                  _vm._l(item, function (text, index) {
                    return _c("span", { key: index }, [_vm._v(_vm._s(text))])
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: i === _vm.result.resultList.length - 1,
                        expression: "i === result.resultList.length - 1",
                      },
                    ],
                    staticClass: "iconfont icon-fuzhi",
                    staticStyle: {
                      color: "#000000",
                      "margin-left": "20px",
                      cursor: "pointer",
                    },
                    on: { click: _vm.copy },
                  }),
                ],
                2
              )
            }),
            0
          ),
          _c("div", { staticClass: "interest-result" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.result,
                    expression: "result",
                  },
                ],
                on: {
                  click: function ($event) {
                    _vm.isShowInfo = !_vm.isShowInfo
                  },
                },
              },
              [_vm._v("详情")]
            ),
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.result,
                  expression: "!result",
                },
              ],
            }),
            _c("span", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.result,
                      expression: "result",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.copyAll },
                },
                [_vm._v("复制")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.result && !_vm.iphone,
                      expression: "result && !iphone",
                    },
                  ],
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.getWORD },
                },
                [_vm._v("导出word")]
              ),
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.share },
                },
                [_vm._v("分享")]
              ),
              _c("span", { on: { click: _vm.feedback } }, [_vm._v("反馈")]),
            ]),
          ]),
          _c("div", { staticClass: "interest-line" }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowInfo,
                  expression: "isShowInfo",
                },
              ],
              staticStyle: { padding: "0 20px" },
            },
            _vm._l(_vm.result.processList, function (item, i) {
              return _c(
                "div",
                { key: i },
                _vm._l(item, function (text, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      style: index !== 0 ? "margin-left:20px" : "",
                    },
                    [_vm._v(_vm._s(text))]
                  )
                }),
                0
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowInfo,
                  expression: "isShowInfo",
                },
              ],
              staticStyle: { "margin-top": "20px", padding: "0 20px" },
            },
            _vm._l(_vm.result.accordanceList, function (item, i) {
              return _c("p", { key: i }, [_vm._v(" " + _vm._s(item) + " ")])
            }),
            0
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          style: { height: "30%" },
          attrs: { position: "top" },
          model: {
            value: _vm.typeShow,
            callback: function ($$v) {
              _vm.typeShow = $$v
            },
            expression: "typeShow",
          },
        },
        [
          _c("cell-radio-group", {
            attrs: { radio: _vm.type, options: _vm.options },
            on: {
              "update:radio": function ($event) {
                _vm.type = $event
              },
              select: _vm.typeChange,
            },
          }),
        ],
        1
      ),
      _c("van-number-keyboard", {
        attrs: {
          show: _vm.show,
          theme: "custom",
          "extra-key": ".",
          "close-button-text": "完成",
        },
        on: {
          blur: function ($event) {
            _vm.show = false
          },
          input: _vm.onInput,
          delete: _vm.onDelete,
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.otherShow,
            callback: function ($$v) {
              _vm.otherShow = $$v
            },
            expression: "otherShow",
          },
        },
        [
          _c(
            "van-checkbox-group",
            {
              on: { change: _vm.otherChange },
              model: {
                value: _vm.other,
                callback: function ($$v) {
                  _vm.other = $$v
                },
                expression: "other",
              },
            },
            [
              _c(
                "van-cell-group",
                _vm._l(_vm.options2, function (option, i) {
                  return _c(
                    "van-cell",
                    {
                      key: i,
                      attrs: { clickable: "", title: option.label },
                      on: {
                        click: function ($event) {
                          return _vm.onClick(option, i)
                        },
                      },
                    },
                    [
                      _c("van-checkbox", {
                        ref: "checkboxes",
                        refInFor: true,
                        attrs: {
                          slot: "right-icon",
                          name: option.value,
                          shape: "square",
                        },
                        slot: "right-icon",
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }